<!-- UploadImgList
  上传图片列表, 可上传多张图片

  props:
    uploadDir: 上传的分类目录

    v-model: [  // 当前列表数据
      {
        url: '',  // 图片路径, 相对于文件上传服务器
      },
    ]

-->

<template>
  <div class="upload-img-list">
    <div v-if="uploadType == 'image'" style="display: flex; flex-wrap: wrap">
      <div v-for="i in showList" :key="i.id" class="img-item-box">
        <div class="upload-img-item">
          <img :src="i.url==''?'':i.url" @click="show(i.index)" style="width: 100%" />
        </div>
        <div v-if="ISshowDelete" class="upload-img-rm" @click="remove(i.id)"><span>x</span></div>
      </div>

      <el-upload ref="uploadRef" class="upload-img-item upload-img-add" :action="uploadActionURL" :class="{ disUoloadSty: noneBtnImg }"
        :show-file-list="false" :on-success="uploadSuccess" :on-change="dealImgChange" :accept="accept"
        :before-upload="handleBeforeUpload" list-type="picture" :data="uploadToken" :multiple="false" :limit="1">
        <!-- .jpg,.jpeg,.png,.JPG,.JPEG,.PBG -->
        <span>+</span>
      </el-upload>
      <ImageViewer v-if="showImageViewer" :srcList="srcList" :index="showIndex" @close="closeViewer" />
    </div>

    <div v-if="uploadType == 'video'" style="display: flex; flex-wrap: wrap">
      <div style="display: flex" v-if="videoForm.showVideoPath != []">
        <div v-for="(i, index) in videoForm.showVideoPath" :key="index" style="margin-right: 15px; position: relative">
          <video :src="i" controls="controls" width="128px" height="128px">
            您的浏览器不支持视频播放
          </video>
          <div class="upload-video-rm" @click="removevideo(i)">
            <span>x</span>
          </div>
        </div>
      </div>
      <el-upload class="upload-img-item upload-img-add" :action="uploadActionURL" :show-file-list="false"
        :on-progress="uploadVideoProcess" :on-success="uploadVideoSuccess" :disabled="false" accept=".mp4"
        :before-upload="beforeUploadVideo" list-type="picture">
        <span v-if="videoFlag == false">+</span>
        <el-progress v-if="videoFlag == true" type="circle" v-bind:percentage="videoUploadPercent"
          style="width: 124px; height: 124px"></el-progress>
      </el-upload>
    </div>
  </div>
</template>

<script>
  import uploadFile from "../../utils/uploadFile";
  import ImageViewer from "./ImageViewer";

  export default {
    name: "UploadImgList",
    components: {
      ImageViewer,
    },
    props: {
      //是否展示删除提示
	  ISshowDelete: {
	    type: Boolean,
	    default: true,
	  },
      ISconfirm: {
        type: Boolean,
        default: false,
      },
      uploadDir: {
        type: String,
        default: ''
      },
      // v-model
      value: {
        type: Array,
        required: false,
      },
      uploadType: {
        type: String,
        required: "image",
      },
      uploadToken: { //上传图片的token
        type: Object,
        default: {}
      },
      rules: { //上传图片规则
        type: Object,
        default: {}
      },
      accept: {
        type: String,
        default: '.jpg,.jpeg,.png,.JPG,.JPEG,.PBG'
      },
      // rulesWidth: {
      // 	type: String,
      // },
      // rulesHeight: {
      // 	type: String,
      // },
      limit: {
        type: Number,
      },
    },
    data() {
      return {
        annexServerUrl: '',
        uploadFileAshx: "",
        showImageViewer: false,
        showIndex: 0,
        videoFlag: false,
        //是否显示进度条
        videoUploadPercent: "",
        //进度条的进度，
        // isShowUploadVideo: false,
        //显示上传按钮
        videoForm: {
          showVideoPath: [],
        },
        noneBtnImg: false,
        maxlimit: null, //上传图片的最大数量
        ruleData: null,
      };
    },
    watch: {
      value() {
        console.log("this.valuethis.value", this.value)
        if (this.value.length > 0 && this.limit) {
          console.log(this.value, "this.valuethis.value")
          this.noneBtnImg = true;
        } else {
          this.noneBtnImg = false;
        }
      },
    },
    computed: {
      uploadActionURL() {

        return uploadFile.makeUploadActionURL(this.uploadDir, {
          ashx: this.uploadFileAshx,
        });
      },
      showList() {

        if (this.value) {
          if (this.value.length > 0 && this.limit) {
            this.noneBtnImg = true;
          } else {
            this.noneBtnImg = false;
          }
          let indexstr = this.uploadActionURL.lastIndexOf("/");
          let lefturl = this.uploadActionURL.substr(0, indexstr + 1);

          this.value.forEach((item) => {
            let urlpght = "http://";
            let urlpght1 = "https://";

            if (item.fPath != "") {
              //item.url = lefturl + item.fPath;
			  console.log(item.fPath.indexOf(urlpght) != -1)
			  console.log(item.fPath.indexOf(urlpght1) != -1)
              if ((item.fPath.indexOf(urlpght)) != -1 || (item.fPath.indexOf(urlpght1)) != -1) {
                item.url = item.fPath;
              } else {
                item.url = lefturl + item.fPath;
              }
            } else {
              item.url = "";
            }
          });

          var tempv = this.value.map((i, index) => ({
            id: i.url,
            url: i.url,
            // url: uploadFile.getUploadFileURL(i.url),
            index,
          }));
          if (this.value.length > 0) {
            for (let ii = 0; ii < this.value.length; ii++) {
              if (this.value[ii].fPath == "") {
                this.value.splice(ii, 1);
              }
            }
          }

          return tempv;

        }
        return [];
      },
      srcList() {
        if (this.value) {
          // console.log(this.value,"this.valuethis.valuethis.value")
          //  var index2 = this.uploadActionURL.lastIndexOf("/");
          // var lefturl = this.uploadActionURL.substr(0, index2 + 1);
          // this.value.forEach((item) => {
          //   item.url = lefturl + item.fPicturePath;
          // });
          return this.value.map((i) => i.url);
          // return this.value.map((i) => uploadFile.getUploadFileURL(i.url));
        }
        return [];
      },
      // showList() {
      // 	if (this.value) {
      // 		return this.value.map((i, index) => ({
      // 			id: i.fPicturePath,
      // 			fPicturePath: uploadFile.getUploadFileURL(i.fPicturePath),
      // 			index
      // 		}));
      // 	}
      // 	return []
      // },
      // srcList() {
      // 	if (this.value) {
      // 		console.log(this.value,"this.valuethis.value")
      // 		return this.value.map(i => uploadFile.getUploadFileURL(i.fPicturePath));

      // 	}
      // 	return []
      // }
    },
    methods: {
      handleBeforeUpload(file) {
        console.log('this.rules', this.rules);
        console.log("file", file);
        let uploadRule = this.rules;
        let imgWidth = "";
        let imgHight = "";
        sessionStorage.setItem("fSourceFileName", file.name);
        let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        // console.log('extension', extension);
        let uploadType = this.accept.indexOf(extension);
        // console.log('uploadType', uploadType);
        let uploadSize = file.size / 1024 / 1024;
        // console.log('uploadSize', uploadSize);
        if (uploadType == -1) {
          this.$message.warning("只能上传" + this.accept + "文件");
          return false;
        }
        if (uploadSize > uploadRule.fMaxSize) {
          this.$message.error("上传图片大小不能超过" + uploadRule.fMaxSize + " MB!");
          return false;
        }


        const _self = this;

        let maxwidth = this.rules.fMaxWidth;
        let maxheight = this.rules.fMaxHeight;
        let flagwidth = false;
        let flagheight = false;
        if (this.rules.fMaxWidth != '' && this.rules.fMaxWidth != 0 && this.rules.fMaxWidth != null) {
          flagwidth = true;
        }
        if (this.rules.fMaxHeight != '' && this.rules.fMaxHeight != 0 && this.rules.fMaxHeight != null) {
          flagheight = true;
        }
        const promise = new Promise(function(resolve, reject) {
          let _URL = window.URL || window.webkitURL;
          let img = new Image();
          img.onload = function() {
            imgWidth = img.width;
            imgHight = img.height;
            let valid = (flagwidth ? (img.width <= maxwidth) : true) && (flagheight ? (img.height <= maxheight) :
              true);
            valid ? resolve() : reject();
          };
          img.src = _URL.createObjectURL(file);
        }).then(
          () => {
            // resolve
            return file;
          },
          () => {
            // reject
            _self.$message.error(
              "上传文件的图片大小不合符标准," +
              (flagwidth ? ('宽需要小于等于' + maxwidth + 'px,') : '') +
              (flagheight ? ('高需要小于等于' + maxheight + 'px,') : '') +
              "当前上传图片的" +
              (flagwidth ? ('宽为：' + imgWidth + 'px ') : '') +
              (flagheight ? ('高为：' + imgHight + 'px') : '')
            );
            return Promise.reject();
            return false; //必须加上return false; 才能阻止
          }
        );






        return promise;
      },
      //上传成功
      uploadSuccess(response, file) {
        console.log('response', response);
        console.log('file', file);
        const result = uploadFile.parseResult(response, this.uploadFileAshx);

        if (this.value) {
          const data = this.value.concat([{
            url: result.url,
            fAnnexTypeID: this.rules.fAnnexTypeID,
            ruleId: this.rules.fRuleID,
            fPath: response.fileName,
            fSourceFileName: file.name,
            fFileExName: file.name.split('.')[0]
          }, ]);
          console.log('data1', data);
          this.$emit("input", data);
          this.$emit("success", data, this.annexServerUrl);
        } else {

          const data = [{
            url: result.url,
            fAnnexTypeID: this.rules.fAnnexTypeID,
            ruleId: this.rules.fRuleID,
            fPath: response.fileName,
            fSourceFileName: file.name,
            fFileExName: file.name.split('.')[0]
          }, ];
          console.log('data2', data);
          this.$emit("input", data);
          this.$emit("success", data, this.annexServerUrl);
        }
        this.$refs.uploadRef.clearFiles();
      },
      remove(id) {
        //是否要提示删除提示
        if(this.ISconfirm){
        this.$confirm('此操作将删除该图片, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$emit("removeIdChange", id);
          const data = this.value.filter((i) => i.url != id);
          this.$emit("input", data);
          this.noneBtnImg = false;
        }).catch(() => {
        });
      }else{
        this.$emit("removeIdChange", id);
          const data = this.value.filter((i) => i.url != id);
          this.$emit("input", data);
          this.noneBtnImg = false;
      }
      },
      show(index) {
        // debugger

        this.showIndex = index;
        this.showImageViewer = true;
        console.log(this.limit);
      },
      closeViewer() {

        this.showImageViewer = false;
      },

      //上传前回调
      beforeUploadVideo(file) {
        var fileSize = file.size / 1024 / 1024 < 100;
        if (
          [
            "video/mp4",
            "video/ogg",
            "video/flv",
            "video/avi",
            "video/wmv",
            "video/rmvb",
            "video/mov",
          ].indexOf(file.type) == -1
        ) {
          this.$message.warning("只能上传'.mp4'格式的视频j");
          return false;
        }
        if (!fileSize) {
          this.$message.warning("视频大小不能超过100MB");
          return false;
        }
        // this.isShowUploadVideo = false;
      },
      //进度条
      uploadVideoProcess(event, file) {
        this.videoFlag = true;
        this.videoUploadPercent = file.percentage.toFixed(0) * 1;
      },
      //上传成功回调
      uploadVideoSuccess(res) {
        // this.isShowUploadVideo = true;
        this.videoFlag = false;
        this.videoUploadPercent = 0;

        //前台上传地址
        //if (file.status == 'success' ) {
        //    this.videoForm.showVideoPath = file.url;
        //} else {
        //     layer.msg("上传失败，请重新上传");
        //}

        //后台上传地址
        // if (res.Code == 0) {
        let obj = {
          id: res.url,
          url: res.RelativeUrl,
        };
        this.videoForm.showVideoPath.push(res.url);
        this.value.push(obj);
        // } else {
        //   this.$message.warning(res.Message);
        // }
      },
      //文件状态改变时的钩子事件
      dealImgChange(file, fileList) {
        console.log("状态" + file);
        // if (this.limit != "undefined" || this.limit != null) {
        //   this.maxlimit = this.limit;
        //   if (fileList.length >= this.maxlimit) {
        //     this.noneBtnImg = true;
        //   }
        // } else {
        //   this.maxlimit = null;
        //   this.noneBtnImg = false;
        // }
      },
      removevideo(url) {
        for (let [index, item] of new Map(
            this.videoForm.showVideoPath.map((item, i) => [i, item])
          )) {
          if (url == item) {
            this.videoForm.showVideoPath.splice(index, 1);
          }
        }
        for (let [index, item] of new Map(
            this.value.map((item, i) => [i, item])
          )) {
          if (url == item.id) {
            this.value.splice(index, 1);
          }
        }
      },
      async getImgUrl() {
        //上传图片前缀
        this.ApiRequestPostNOMess(
          "/api/mall/ebbase/para-value/get-config"
        ).then(
          (result) => {
            this.annexServerUrl = result.obj.annexServerUrl
            this.uploadFileAshx = result.obj.annexServerUrl + 'upload';

          },
          (rej) => {}
        );
      }
    },
    async mounted() {
      await this.getImgUrl();
    },
  };
</script>

<style scoped lang="scss">
  .img-item-box {
    position: relative;
    height: 100px;
    width: 100px;
    flex-shrink: 0;
    background-color: #fbfdff;
    margin-right: 15px;
    border-radius: 10px;
  }

  ::v-deep .uoloadSty .el-upload--picture-card {
    width: 110px;
    height: 110px;
    line-height: 110px;
  }

  .disUoloadSty {
    display: none !important;
  }

  .upload-img-list {
    border-width: 2px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .upload-img-item {
    height: 100px;
    width: 100px;
    margin-right: 15px;
    flex-shrink: 0;
    overflow: hidden;
    background-color: #fbfdff;
    position: relative;
    border: 2px solid #f2f2f2;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .upload-img-rm {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: -10px;
    right: -10px;
    background-color: #fc0707;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    cursor: pointer;
  }

  .upload-video-rm {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: -10px;
    right: -10px;
    background-color: #eb3941;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    cursor: pointer;
  }

  .upload-img-add {
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: dashed;
    border-width: 2px;
    border-color: #d7d7d7;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .upload-img-add span {
    font-size: 45px;
    color: #d7d7d7;
  }

  .upload-img-list::v-deep {
    .el-upload {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
